//COLORS

$color-blue : #1167B6;
$color-blue-light: #2997F6;
$color-red: #CD3A18;
$color-white: #ffffff;
$color-grey: #4E4E4E;

//FONTS
$font-serif : 'Spectral', serif;
$font-sans-serif: 'Roboto', sans-serif;