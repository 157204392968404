.footer{
	height: 20rem;
	//background-color: $color-blue;
	padding: 2rem 8rem;
}

.disclaimer{
	font-family: $font-serif;
	font-size: 1.3rem;
	color: $color-blue;
	text-align: center;
}

.social{
	padding: 1rem 0;
}

.social__wrapper{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.social__icon{
	max-width: 3rem;
	padding-bottom: .8rem;
}

.footer__logo{
	height: 7rem;
}