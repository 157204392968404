.header{
	//height: 12rem;
	background: $color-white;
	//border-bottom: solid .1rem $color-blue-light;
	//margin-bottom: 2rem;
	display: flex;
	align-items: center;

	@media only screen and (max-width: $breakpoint-md) {
		justify-content: center;
	}

}

.img-fluid{max-width: 100%;}

.logo{padding-bottom: .5rem;}

.logo__rethink{
	width: 7rem;
	position: absolute;
	top: 3rem;
	right: 9rem;
}