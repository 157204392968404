$width: 100%; //Container Width
$resize: 100% - 5%; //Container Width
$gutter: 0.5; //gutter size
$columns: 12; //number of columns
$slug: is !default; // change it to col if you're comfortable
$breakpoint-md:  48em;  // tablets
$breakpoint-lg: 56.25em; // desktop
$breakpoint-xlg: 68.75em; // HD

html,
body {
  margin: 0px;
}

/* For mobile phones: */
.container {
  width: $resize;
  margin: auto;
  overflow: hidden;
}
.columns {
  display: block;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -.5rem;
  margin-left: -.5rem;
}
@for $i from 1 through $columns {
  .#{$slug}-#{$i} {
    width: $width + (100% - $width);
    margin: +#{$gutter}em 0em 0em +#{$gutter}em;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
/* For Tablets: */
@media only screen and (min-width: $breakpoint-md) {
  .container {
    width: $resize;
    margin: auto;
  }
  .columns {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  @for $i from 1 through $columns {
    .#{$slug}-#{$i} {
      flex: 0 0 auto;
      box-sizing: border-box;
      -webkit-box-flex: 0;
      flex-basis: ($resize/$columns * $i)- $gutter;
      max-width: ($resize/$columns * $i)- $gutter;
      margin: +#{$gutter}rem 0rem 0rem +#{$gutter/2}rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}
/* For Desktops: */
@media only screen and (min-width: $breakpoint-xlg) {
  .container {
    width: $resize;
    margin: auto;
  }
  .columns {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  @for $i from 1 through $columns {
    .#{$slug}-#{$i} {
      flex: 0 0 auto;
      box-sizing: border-box;
      -webkit-box-flex: 0;
      flex-basis: ($resize/$columns * $i)- $gutter;
      max-width: ($resize/$columns * $i)- $gutter;
      margin: +#{$gutter}rem 0rem 0rem +#{$gutter/2}rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}
/* Alignment: */
.start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
.center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

