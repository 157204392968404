h2{
	font-family: $font-serif;
	font-size: 3.2rem;
	color: $color-white;
}

h3{
	font-family: $font-serif;
	font-size: 2.4rem;
	color: $color-white;
	margin: .5rem 0;
}

p{
	font-family: $font-sans-serif;
	font-size: 1.6rem;
	color: $color-white;
	font-weight: 100;
	font-style: normal;
}