.action-network {
}

.action-network__wrapper {
	background: $color-blue-light;
	padding: 2rem;
}

.donate_amount:hover { color: $color-blue !important; }

//label{
//	font-family: $font-sans-serif;
//	font-size: 1.5rem;
//	color: $color-white;
//}
//
//p{
//	font-family: $font-serif;
//	color: $color-white;
//}
#can_embed_form #logo_wrap { display: none !important; }

#can_embed_form, #donate_auto_modal {
	//background-color: $color-blue !important;
}

#can_embed_form h2 { display: none !important; }

.pitch {
	border-bottom: solid .1rem $color-white;
	padding-bottom: 2rem;
}

/////////

#can_main_col {
	width: 100% !important;
	//float: right !important;

	#donation_recipient_wrap {
		flex-direction: column !important;
		align-items: center;
		padding-bottom: 2rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		.recipient_avatar_link { display: none; }
	}
}

#can_sidebar {
	width: 100% !important;
	//float: left !important;
}

.action_sidebar { display: none !important; }

#tip_jar { display: none; }

#can_embed_form {
	background: $color-white;
	padding: 2rem;
	color: $color-grey;
	font-family: $font-sans-serif;
	border-radius: .5rem;

	.clearfix { display: flex !important;
		flex-direction: column-reverse !important;
		zoom: 1; }

	input:focus { outline: none; }

	/////// STATUS TRACKER /////////
	.action_status_tracker {
		margin-bottom: 40px;
		margin: 20px 0 20px;
		padding: 0 20px 20px;
		border-bottom: 1px solid rgba(0, 0, 0, .05);

		.centertext { text-align: center; }

		.action_status_running_total {
			text-align: center;
			color: $color-red;
			font-family: $font-sans-serif;
			font-weight: 400;
			font-size: 1.6rem;
			margin-bottom: 1rem;
		}

		.action_status_status_bar {
			background-color: #cdcdcd;
			box-shadow: 0 0 17px 2px rgba(0, 0, 0, 0.1) inset, 0 0 1px 0 rgba(0, 0, 0, 0.2) inset;
			height: 20px;
			overflow: hidden;
			border-radius: 3px;
			margin-bottom: 10px;
		}

		.action_status_status_bar > span {
			display: block;
			height: 70px;
			position: relative;
			top: -50px;
		}

		.action_status_status_bar-grow {
			display: block;
			position: relative;
			z-index: 1;
			height: 100%;
			background-color: $color-blue;
		}

		.action_status_goal {
			text-align: center;
			font-size: 1.2rem;
			line-height: 2rem;
		}

		/////// DONATION AMOUNTS /////////

	}

	.check_radio_field.donate_amount_wrap {
		//flex-direction: row !important;
		//flex-wrap: wrap;
		//justify-content: center;
		//text-align: center;
		display: inline-block !important;
		padding-left: 1.5rem !important;
	}

	.donate_amount {
		padding: 1%;
		float: left;
		margin: 0 2% 10px 0;
		background-color: #f5f5f5;
		background-color: rgba(0, 0, 0, 0.07);
		border-radius: 3px;
		font-size: 14px;
		font-weight: 700;
		line-height: 34px;
		position: relative;
		text-align: center;
		cursor: pointer;
		min-width: 54px;
		width: 30%;
		box-sizing: border-box;
		transition: all .5s ease-in-out;

		&:hover {
			background-color: $color-blue;
			color: $color-white !important;
		}
	}

	.error_checkbox { color: $color-red; }

	.other_label {
		input {
			max-width: 80%;
			border: none;
			border-radius: .5rem;
			text-align: center;
		}

		&:hover {
			background-color: #f5f5f5 !important;
			background-color: rgba(0, 0, 0, 0.07) !important;
		}
	}

	.donate_recurring {
		padding: 20px 20px 10px !important;
		cursor: pointer !important;
		background-color: #f5f5f5 !important;
		border-radius: 3px !important;
		margin-bottom: 10px !important;
	}

	.donate_recurring_inner {
		flex-direction: column !important;
		text-align: center;

		label{padding-bottom: 2rem; }
	}

	#donation_welcome{
		flex-direction: column !important;
		align-items: center !important;
		justify-content: space-between !important;
		height: 18rem !important;
	}

	.can_block {
		display: block !important;
	}

	.can_relative {
		position: relative !important;
	}

	.donate_amount input[type="radio"], #can_embed_form .tip_label input[type="radio"] {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
	}

	///////DONATION LOGIN /////////
	#donate_login {
		background-color: #f5f5f5;
		background-color: rgba(0, 0, 0, 0.07);
		padding: 2rem;
		border-radius: .5rem;
		margin: 1rem;
	}

	///////PAYMENT DETAILS ////////

	input[type="text"], input[type="email"] {
		line-height: 25px !important;
		border: 1px solid #cfcfcf !important;
		background-color: #fff !important;
		box-sizing: border-box !important;
		height: 33px !important;
		padding: 4px 9px !important;
		width: 100% !important;
		transition: border-color .3s linear, background-color .3s linear !important;
		border-radius: 3px !important;
		box-shadow: none !important;
		outline: none !important;
		text-indent: 0 !important;
	}

	ul.clearfix.nofloat {
		flex-direction: column !important;
		padding-left: 0;
		list-style: none;
	}

	//// SELECT INPUTS //////

	select.can_select {
		opacity: 0 !important;
		position: absolute !important;
		z-index: 999999 !important;
		left: 0 !important;
		top: 0 !important;
		height: 100% !important;
	}

	//select {
	//	color: #4e4e4e !important;
	//	font-size: 16px !important;
	//	line-height: 30px !important;
	//	font-family: 'Helvetica', Arial, sans-serif !important;
	//	font-weight: 400 !important;
	//	outline: none !important;
	//	box-shadow: none !important;
	//}

	span.can_select {
		width: 100% !important;
		height: 34px !important;
		text-align: left;
		background-image: url(/images/dropdownarrow.png) !important;
		background-position: right 12px !important;
		background-repeat: no-repeat !important;
		background-color: $color-white !important;
	}

	select.can_select, span.can_select {
		line-height: 34px !important;
		border: 1px solid #cfcfcf !important;
		//background: url(/images/dropdownarrow.png) right 12px / 23px 9px no-repeat #FFFFFF!important;
		//background-size: 23px 9px!important;
		background: $color-white !important;
		box-sizing: border-box !important;
		border-radius: 3px !important;
		padding: 0 30px 0 10px !important;
		zoom: 1 !important;
		white-space: nowrap !important;
		display: block !important;
		margin-bottom: 15px !important;
		width: 100% !important;
		max-width: 100% !important;
		//font-size: 1.6rem !important;
		//-webkit-appearance: menulist !important;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;

		&:after {
			content: "";
			position: absolute;
			z-index: 2;
			right: 15px;
			top: 15px;
			//margin-top: -1px;
			height: 0;
			width: 0;
			border-top: 6px solid $color-blue;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			pointer-events: none;
		}
	}

	#can_embed_form select {
		height: 34px !important;
		padding: 7px !important;
		width: 100% !important;
		line-height: 34px !important;
		border: 1px solid #cfcfcf !important;
		background-color: #fff !important;
		transition: border-color .3s linear, background-color .3s linear !important;
		border-radius: 3px !important;
		transition: inherit !important;
	}

	.can_select span.can_selectInner {

		@media only screen and (min-width: 48rem) {
			//padding: .8rem !important;
		}
	}

	//

	//select.can_select, #can_embed_form span.can_select {
	//	line-height: 34px!important;
	//	border: 1px solid #CFCFCF!important;
	//	background: url(/images/dropdownarrow.png) right 12px no-repeat #FFFFFF!important;
	//	background-size: 23px 9px!important;
	//	box-sizing: border-box!important;
	//	border-radius: .5rem !important;
	//	padding: 0 30px 0 10px!important;
	//	zoom: 1!important;
	//	white-space: nowrap!important;
	//	display: block!important;
	//	margin-bottom: 15px!important;
	//	width: 100%!important;
	//	max-width: 100%!important;
	//}

	#can_embed_form_inner * {
		margin: 0 !important;
		padding: 0 !important;
		border: 0 !important;
		background: none transparent !important;
		font-size: 100% !important;
		font: inherit !important;
		color: #4e4e4e !important;
		vertical-align: baseline !important;
		text-shadow: none !important;
		text-align: left !important;
		text-decoration: none !important;
		z-index: 1 !important;
		box-shadow: none !important;
		outline: none !important;
		text-indent: 0 !important;
		border-radius: 0 !important;
		opacity: 1 !important;
		float: none !important;
		position: relative !important;
		top: auto !important;
		bottom: auto !important;
		left: auto !important;
		right: auto !important;
	}

	.can_select span.can_selectInner {
		width: 100% !important;
	}

	.can_select span {
		overflow: hidden !important;
	}

	.donate_state, .donate_cc_month, .donate_cc_year {
		display: block !important;
		position: relative !important;
	}

	.cc_li_half, .cc_li_quarter, .cc_li_third, .full_list {
		margin: .5rem;
		padding: .5rem;

		label {
			line-height: 20px !important;
			font-size: 12px !important;
			color: #909090 !important;
			width: 100% !important;
			margin-bottom: 2px !important;
		}

		input {
			width: 100%;
			border-radius: .5rem;
			line-height: 25px !important;
			border: 1px solid rgb(207, 207, 207) !important;
			background-color: rgb(255, 255, 255) !important;
			box-sizing: border-box !important;
			padding: 0 3rem 0 1rem !important;
			font-weight: 400 !important;
			outline: none !important;
			box-shadow: none !important;
		}

		//select {
		//	opacity: 1 !important;
		//	//max-width: 65% !important;
		//	line-height: 25px !important;
		//	border: 1px solid rgb(207, 207, 207) !important;
		//	background-color: rgb(255, 255, 255) !important;
		//	border-radius: .5rem;
		//}

		.error_input, .error_input_cc {
			background-color: #f4b4b4 !important;
			border: 1px solid #a31010 !important;
			color: #333 !important;
		}
	}

	/////BUTTON//////

	#donate_form_button-li {
		display: flex;
		flex-direction: column;
		margin: 1rem 1rem 0;
	}

	//#donate_form_button, .button, .button_red {
	//	background: $color-red !important;
	//	border-radius: .5rem !important;
	//	border: none !important;
	//	padding: 1rem !important;
	//	color: $color-white !important;
	//	font-weight: 700 !important;
	//	font-size: 1.6rem !important;
	//	margin: 1rem !important;
	//	transition: all .5s ease-in-out !important;
	//
	//	&:hover {
	//		background: $color-blue !important;
	//	}
	//}

	input[type="submit"], .button, #donate_auto_modal input[type="submit"], #donate_auto_modal .button {
		width: 100% !important;
		max-width: 100% !important;
		text-decoration: none !important;
		font-size: 1.4rem !important;
		font-weight: 700 !important;
		border-radius: .5rem !important;
		padding: 5px 10px !important;
		border: none !important;
		border: 1px solid rgba(0, 0, 0, .04) !important;
		line-height: 1.5em !important;
		text-shadow: 0 1px 0 rgba(0, 0, 0, .5) !important;
		transition: all .25s linear !important;
		cursor: pointer !important;
		color: $color-white !important;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0) inset !important;
		background-color: $color-red !important;
		text-align: center !important;
		outline: none !important;
		-webkit-appearance: none !important;
		box-sizing: border-box !important;
		display: block !important;
		white-space: normal !important;
	}

	input[type="submit"]:hover, .button:hover, #donate_auto_modal input[type="submit"]:hover, #donate_auto_modal .button:hover {
		background-color: $color-blue !important;
		color: $color-white !important;
	}

	#error_message {
		text-align: center;
		padding-bottom: 2rem;
	}

	///////SHARING////////
	#d_sharing {
		ul {
			list-style: none;
			text-align: center;
			padding-left: 0;

			li { margin-bottom: .5rem; }
		}
	}
}

#edit_d_sharing_opts, #edit_d_sharing_opts-close { display: none; }

.action_description { display: none; }

.hide { display: none; }
