.our-team{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.team{
	list-style: none;
	padding: .5rem 0;
	width: 23rem;
	margin: 0;
}

.team__item{
	font-family: $font-serif;
	font-size: 1.4rem;
	color: $color-white;
	font-weight: 200;
}

.team__item--header{
	font-size: 1.6rem;
	font-weight: 400;
}